import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { GenericTableComponent } from './generic-table.component';
import { GenericTableCustomTemplateDirective, GenericTableNoDataDirective } from './generic-table.directive';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        NgScrollbarModule
    ],
    declarations: [GenericTableComponent, GenericTableNoDataDirective, GenericTableCustomTemplateDirective],
    exports: [GenericTableComponent, GenericTableNoDataDirective, GenericTableCustomTemplateDirective]
})
export class GenericTableModule {}
