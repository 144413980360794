import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgIdleModule } from '@ng-idle/core';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedModule } from '@app/shared/shared.module';

import { MainComponent } from './main/main.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FuseNavigationModule } from './navbar/navigation/navigation.module';
import { NotificationPanelComponent } from './notification-panel/notification-panel.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { VaultModalComponent } from './vault-modal/vault-modal.component';

@NgModule({
    declarations: [MainComponent, ToolbarComponent, NavbarComponent, NotificationPanelComponent, VaultModalComponent],
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,

        FuseNavigationModule,

        NgIdleModule.forRoot(),

        MatDialogModule,
        MatIconModule,
        MatMenuModule,
        MatBadgeModule,
        MatTooltipModule,
        MatToolbarModule,
        MatButtonModule,
        MatListModule,
        MatProgressSpinnerModule
    ]
})
export class LayoutModule {}
