import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

type ThemeServiceScheme = 'light' | 'dark';

@Injectable({
    providedIn: 'root'
})
export class ThemeService implements OnDestroy {
    private _unsubscribeAll: Subject<void>;

    // Scheme
    private _scheme$: BehaviorSubject<ThemeServiceScheme>;

    set scheme(scheme: ThemeServiceScheme) {
        this._scheme$.next(scheme);
    }

    get scheme(): ThemeServiceScheme {
        return this._scheme$.value;
    }

    // Theme
    private _theme$: BehaviorSubject<string>;

    set theme(theme: string) {
        this._theme$.next(theme);
    }

    get theme(): string {
        return this._theme$.value;
    }

    constructor(@Inject(DOCUMENT) private _document: Document) {
        // Subjects
        this._unsubscribeAll = new Subject();
        this._scheme$ = new BehaviorSubject('light');
        this._theme$ = new BehaviorSubject('theme-blue');

        // Update theme on change
        this._theme$.pipe(takeUntil(this._unsubscribeAll)).subscribe((theme) => {
            const elements = [this._document.body];

            elements.forEach((element) => {
                // Find the class name for the previously selected theme and remove it
                element.classList.forEach((className: string) => {
                    if (className.startsWith('theme-')) {
                        element.classList.remove(className, className.split('-')[1]);
                    }
                });

                // Add class name for the currently selected theme
                element.classList.add(theme);
            });
        });

        // Update scheme on change
        this._scheme$.pipe(takeUntil(this._unsubscribeAll)).subscribe((scheme) => {
            // Remove class names for all schemes
            this._document.body.classList.remove('light', 'dark');

            // Add class name for the currently selected scheme
            this._document.body.classList.add(scheme);
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
