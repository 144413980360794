import { ENotificationType } from '@mapuilabs/mpl-interfaces';

export const ICONS_CONFIG: { [key: string]: string } = {
    [ENotificationType.Loan]: 'swap_horiz',
    [ENotificationType.Borrowing]: 'swap_horiz',
    [ENotificationType.Preparation]: 'custom-prep-nav'
};

export const BADGES_CONFIG: Array<IBadgeConfig> = [
    {
        id: 'exchanges.dashboard',
        notificationTypes: [ENotificationType.Loan, ENotificationType.Preparation, ENotificationType.Borrowing],
        color: '#F44336'
    }
];

export interface IBadgeConfig {
    id: string;
    color: string;
    notificationTypes: ENotificationType[];
}
