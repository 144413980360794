import { Injectable, OnDestroy } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { NavBarService } from '@core/services/nav-bar-subject/nav-bar-subject.service';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class PageHeaderService implements OnDestroy {
    private _unsubscribeAll: Subject<void>;
    private _isNavBarUnfolded: boolean;

    public isCompactViewMode: BehaviorSubject<boolean>;

    constructor(
        private _mediaObserver: MediaObserver,
        private _navBarService: NavBarService
    ) {
        // Subjects
        this._unsubscribeAll = new Subject();
        this.isCompactViewMode = new BehaviorSubject(false);

        // Update isCompactView on responsives & navBarUnfolded changes
        merge(
            this._navBarService.navBarUnfolded.pipe(tap((value: boolean) => (this._isNavBarUnfolded = value))),
            this._mediaObserver.asObservable()
        )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => this._updateIsCompactView());
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    private _updateIsCompactView(): void {
        this.isCompactViewMode.next(
            this._mediaObserver.isActive('lt-md') || (this._mediaObserver.isActive('md') && this._isNavBarUnfolded)
        );
    }
}
