import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';
import { BackButtonDirective } from '../back-button/back-button.directive';
import { PageHeaderButtonLabelComponent } from './page-header-button/page-header-button-label/page-header-button-label.component';
import { PageHeaderButtonComponent } from './page-header-button/page-header-button.component';
import { PageHeaderSearchBarButtonComponent } from './page-header-search-bar/page-header-search-bar-button/page-header-search-bar-button.component';
import { PageHeaderSearchBarComponent } from './page-header-search-bar/page-header-search-bar.component';
import { PageHeaderComponent } from './page-header.component';
import { PageHeaderService } from './page-header.service';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        TranslocoModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatTooltipModule,
        MatBadgeModule
    ],
    declarations: [
        PageHeaderButtonComponent,
        PageHeaderButtonLabelComponent,
        PageHeaderComponent,
        PageHeaderSearchBarButtonComponent,
        PageHeaderSearchBarComponent,
        BackButtonDirective
    ],
    exports: [
        PageHeaderButtonComponent,
        PageHeaderButtonLabelComponent,
        PageHeaderComponent,
        PageHeaderSearchBarButtonComponent,
        PageHeaderSearchBarComponent
    ],
    providers: [PageHeaderService]
})
export class PageHeaderModule {}
