import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MessageIconBoxComponent } from './message-icon-box.component';

@NgModule({
    imports: [CommonModule, MatIconModule, MatProgressSpinnerModule],
    declarations: [MessageIconBoxComponent],
    exports: [MessageIconBoxComponent]
})
export class MessageIconBoxModule {}
