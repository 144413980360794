import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { EGroupType, EMaPUIPermission, IMember } from '@mapuilabs/mpl-interfaces';

import { FuseSidebarService } from '@app/shared/components/fuse-sidebar/fuse-sidebar.service';

import { Auth } from '@core/auth/auth.service';
import { IdentityService } from '@core/services/identity/identity.service';
import { NotificationService } from '@core/services/notification/notification.service';
import { PermissionService } from '@core/services/permission/permission.service';
import { ThemeService } from '@core/services/theme/theme.service';
import { Utils } from '@core/services/Utils';

@Component({
    selector: 'mpx-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
    public user: IMember;
    public ght: string;

    public canAccessHome: boolean;
    public canAccessProfile: boolean;
    public canAccessEstablishment: boolean;

    public get scheme(): string {
        return this._themeService.scheme;
    }

    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _identityService: IdentityService,
        private _permissionService: PermissionService,
        private _router: Router,
        private _themeService: ThemeService,
        private Auth: Auth,
        public notificationService: NotificationService
    ) {
        this.user = this._identityService.user;

        this.canAccessHome = this._permissionService.authorize(EMaPUIPermission.Home_SeeMenuItem);
        this.canAccessProfile = this._permissionService.authorize(EMaPUIPermission.User_SeeMenuItem);
        this.canAccessEstablishment = this._permissionService.authorize(EMaPUIPermission.Establishment_SeeMenuItem);
    }

    ngOnInit(): void {
        if (this._identityService.hospital.groups) {
            if (Utils.ensurePopulated(this._identityService.hospital.groups)) {
                for (const group of this._identityService.hospital.groups) {
                    if (group.type === EGroupType.GHT) {
                        this.ght = group.name;
                    }
                }
            }
        }
    }

    public toggleOpen(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    public openNotifPanel(): void {
        this._fuseSidebarService.getSidebar('quickPanel').toggleOpen();
    }

    public async logout(): Promise<void> {
        await this.Auth.logout();
        this._router.navigateByUrl('/auth/login');
    }

    public setScheme(scheme: 'light' | 'dark'): void {
        this._themeService.scheme = scheme;
    }
}
