import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { TranslocoModule } from '@jsverse/transloco';

import { DirectivesModule } from '@app/shared/directives/directives';
import { PipesModule } from '@app/shared/pipes/pipes.module';

import { FileDragNDropComponent } from './file-drag-n-drop.component';
import { DragNDropDirective } from './file-drag-n-drop.directive';

@NgModule({
    imports: [
        PipesModule,
        CommonModule,
        DirectivesModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatListModule,
        MatProgressBarModule,
        TranslocoModule
    ],
    declarations: [FileDragNDropComponent, DragNDropDirective],
    exports: [FileDragNDropComponent, DragNDropDirective]
})
export class FileDragNDropModule {}
