import { Route } from '@angular/router';

import { EAdminPanelPermission, EMaPUIPermission } from '@mapuilabs/mpl-interfaces';

import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './core/auth/guards/auth-guard';
import { AuthGuardChild } from './core/auth/guards/auth-guard-child';
import { NoAuthGuard } from './core/auth/guards/no-auth-guard';
import { MainComponent } from './layout/main/main.component';

const defaultHome = '/home';

export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: defaultHome },

    // Auth routes for guests
    {
        path: 'auth',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: AuthComponent,
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
    },

    // Modules routes for authenticated list
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuardChild],
        component: MainComponent,
        children: [
            {
                path: 'home',
                data: { access: EMaPUIPermission.Home_SeeMenuItem },
                loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
            },
            {
                path: 'adm',
                data: { access: EAdminPanelPermission.AdminPanel_SeeMenuItem },
                children: [
                    {
                        path: 'ars',
                        loadChildren: () => import('./admin/ars/ars.module').then((m) => m.AdminARSModule)
                    },
                    {
                        path: 'hospital',
                        loadChildren: () => import('./admin/hospitals/hospitals.module').then((m) => m.HospitalsModule)
                    },
                    {
                        path: 'pharmacy',
                        loadChildren: () =>
                            import('./admin/pharmacies/pharmacies.module').then((m) => m.PharmaciesModule)
                    },
                    {
                        path: 'member',
                        loadChildren: () => import('./admin/members/members.module').then((m) => m.MembersModule)
                    },
                    {
                        path: 'group',
                        loadChildren: () => import('./admin/groups/groups.module').then((m) => m.GroupsModule)
                    },
                    {
                        path: 'labo',
                        loadChildren: () => import('./admin/labos/labos.module').then((m) => m.AdminLabosModule)
                    },
                    {
                        path: 'purchase-group',
                        loadChildren: () =>
                            import('./admin/purchase-groups/purchase-groups.module').then(
                                (m) => m.AdminPurchaseGroupsModule
                            )
                    },
                    {
                        path: 'release-notes',
                        loadChildren: () =>
                            import('./admin/release-notes/release-notes.module').then((m) => m.ReleaseNotesModule)
                    },
                    {
                        path: 'sandbox',
                        loadChildren: () => import('./admin/sandbox/sandbox.module').then((m) => m.SandboxModule)
                    },
                    {
                        path: 'online-users',
                        loadChildren: () =>
                            import('./admin/online-users/online-users.module').then((m) => m.OnlineUsersModule)
                    },
                    {
                        path: 'cron',
                        loadChildren: () => import('./admin/cron/cron.module').then((m) => m.CronModule)
                    },
                    {
                        path: 'role',
                        loadChildren: () => import('./admin/roles/roles.module').then((m) => m.RolesModule)
                    },
                    {
                        path: 'hospistock',
                        loadChildren: () => import('./admin/hospistock/admin-hs.module').then((m) => m.AdminHsModule)
                    }
                ]
            },
            {
                path: 'pharmacy',
                children: [
                    {
                        path: 'preparations',
                        loadChildren: () =>
                            import('./pharmacy/preparations/preparations.module').then((m) => m.PreparationsModule)
                    },
                    {
                        path: 'equivalences',
                        loadChildren: () =>
                            import('./pharmacy/equivalences/equivalences.module').then((m) => m.EquivalencesModule)
                    }
                ]
            },
            {
                path: 'exchange',
                children: [
                    {
                        path: 'billing',
                        data: { access: EMaPUIPermission.Billing_SeeMenuItem },
                        loadChildren: () => import('./exchange/billing/billing.module').then((m) => m.BillingModule)
                    },
                    {
                        path: 'archives',
                        data: { access: EMaPUIPermission.Archives_Read },
                        loadChildren: () => import('./exchange/archives/archives.module').then((m) => m.ArchivesModule)
                    },
                    {
                        path: 'dashboard',
                        data: { access: EMaPUIPermission.Dashboard_SeeMenuItem },
                        loadChildren: () =>
                            import('./exchange/dashboard/dashboard.module').then((m) => m.DashboardModule)
                    },
                    {
                        path: 'statistics',
                        data: { access: EMaPUIPermission.Dashboard_SeeMenuItem },
                        loadChildren: () =>
                            import('./exchange/statistics/statistics.module').then((m) => m.StatisticsModule)
                    }
                ]
            },
            {
                path: 'hospiboard',
                children: [
                    {
                        path: 'pharmacy',
                        loadChildren: () =>
                            import('./hospiboard/pharmacy/hb-pharmacy.module').then((m) => m.HospiboardPharmacyModule)
                    },
                    {
                        path: 'labo',
                        loadChildren: () =>
                            import('./hospiboard/labo/hb-labo.module').then((m) => m.HospiboardLaboModule)
                    }
                ]
            },
            {
                path: 'hospistock',
                loadChildren: () => import('./hospistock/hs.module').then((m) => m.HsModule)
            },
            {
                path: 'setting',
                children: [
                    {
                        path: 'user-account',
                        loadChildren: () =>
                            import('./setting/user-account/user-account.module').then((m) => m.UserAccountModule)
                    },
                    {
                        path: 'group',
                        loadChildren: () => import('./setting/groups/groups.module').then((m) => m.GroupsModule)
                    },
                    {
                        path: 'hospital-account',
                        loadChildren: () =>
                            import('./setting/hospital-account/hospital-account.module').then(
                                (m) => m.HospitalAccountModule
                            )
                    }
                ]
            }
        ]
    },
    { path: '**', redirectTo: defaultHome }
];
