export const regExpTrue = new RegExp('^(?:yes|oui|true)$', 'i');
export const regExpCladimed = new RegExp('^[A-Z][0-9]{2}[A-Z]{2}[0-9]{2}$', 'i');
export const regExpFalse = new RegExp('^(?:no|non|false)$', 'i');
export const regExpUnknown = new RegExp('^(?:inconnu|unknown)$', 'i');
export const regExpBoolean = new RegExp(regExpTrue.source + '|' + regExpFalse.source, 'i');
export const regExpNotApplicable = new RegExp('^(?:nonapplicable|notapplicable)$', 'i');
export const regExpNumber = new RegExp('^[0-9]+$');
export const regExpMimeTypes = new RegExp('^\\w+/[-+.\\w]+$');
export const regExpHexColor = new RegExp('^#[A-F0-9]{6}$', 'i');
export const regExpFileExtension = new RegExp('^.\\w+$');
export const regExpAlphanumeric = new RegExp('[\\w .,/]+');
export const regExpDecimalNumber = new RegExp('^[0-9]*[.,]?[0-9]*$');
export const regExpCIP7 = new RegExp('^[0-9]{7}$');
export const regExpCIP13 = new RegExp('^[0-9]{13}$');
export const regExpCIP = new RegExp(regExpCIP7.source + '|' + regExpCIP13.source);
export const regExpUCD7 = new RegExp('^[0-9]{7}$');
export const regExpUCD13 = new RegExp('^[0-9]{13}$');
export const regExpUCD = new RegExp(regExpUCD7.source + '|' + regExpUCD13.source);
export const regExpPHARMACODE = new RegExp('^[0-9]{0,7}$');
export const regExpSpecialCharacters = new RegExp('[^\\w]|_', 'g');
export const regExpFirstZeros = new RegExp('^0+', 'g');
export const regExpFirstZerosAndSpecialCharacters = new RegExp(
    regExpSpecialCharacters.source + '|' + regExpFirstZeros.source,
    'g'
);
export const regExpFiness = new RegExp('^[0-9]{9}$');
