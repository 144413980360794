import { EMpStatus } from '@mapuilabs/mpl-interfaces';

export const CMpStatus: { name: string; value: EMpStatus; translate: string }[] = [
    {
        name: 'Ok',
        value: EMpStatus.Ok,
        translate: 'SNACKBAR.OK'
    },
    {
        name: 'CloseByUser',
        value: EMpStatus.CloseByUser,
        translate: 'SNACKBAR.NO_RESULT'
    },
    {
        name: 'RejectByServer',
        value: EMpStatus.RejectByServer,
        translate: 'SNACKBAR.SERVER'
    },
    {
        name: 'NoDataProvided',
        value: EMpStatus.NoDataProvided,
        translate: 'SNACKBAR.DATA'
    },
    {
        name: 'NoResult',
        value: EMpStatus.NoResult,
        translate: 'SNACKBAR.NO_RESULT'
    },
    {
        name: 'NoAccess',
        value: EMpStatus.NoAccess,
        translate: 'SNACKBAR.ACCESS'
    },
    {
        name: 'LoginFail',
        value: EMpStatus.LoginFail,
        translate: 'SNACKBAR.LOGIN.LOGIN_FAIL'
    },
    {
        name: 'LoginCaptchaFail',
        value: EMpStatus.LoginCaptchaFail,
        translate: 'SNACKBAR.LOGIN.CAPTCHA_FAIL'
    },
    {
        name: 'TwoFaFail',
        value: EMpStatus.TwoFaFail,
        translate: 'SNACKBAR.LOGIN.TWOFA_FAIL'
    },
    {
        name: 'LoginEmailNotVerified',
        value: EMpStatus.LoginEmailNotVerified,
        translate: 'SNACKBAR.LOGIN.EMAIL_NOT_VERIFIED'
    },
    {
        name: 'RejectByUser',
        value: EMpStatus.RejectByUser,
        translate: ''
    },
    {
        name: 'EmailAlreadyInUse',
        value: EMpStatus.EmailAlreadyInUSe,
        translate: 'SNACKBAR.EMAIL_IN_USE'
    },
    {
        name: 'PwdTooShort',
        value: EMpStatus.PwdTooShort,
        translate: 'SNACKBAR.PWD_SHORT'
    },
    {
        name: 'UserAlreadyInUse',
        value: EMpStatus.UserAlreadyInUSe,
        translate: 'SNACKBAR.USER_IN_USE'
    },
    {
        name: 'CGUNotAccepted',
        value: EMpStatus.CGUNotAccepted,
        translate: 'SNACKBAR.CGU_NOT_ACCEPTED'
    },
    {
        name: 'NotAvailableAnymore',
        value: EMpStatus.NotAvailableAnymore,
        translate: 'SNACKBAR.NOT_AVAILABLE_ANYMORE'
    },
    {
        name: 'CPSCardUndetected',
        value: EMpStatus.CPSCardUndetected,
        translate: 'SNACKBAR.CPS_CARD_UNDETECTED'
    },
    {
        name: 'NoValidation',
        value: EMpStatus.NoValidation,
        translate: 'SNACKBAR.NO_VALIDATION'
    }
];
