import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Sort } from '@angular/material/sort';

import {
    EMaPUIPermission,
    IFilter,
    IHttpRequestParam,
    IMember,
    IPaginationOptions,
    IPaginationResult,
    Member
} from '@mapuilabs/mpl-interfaces';
import { Observable } from 'rxjs';

import { CrudTemplateService } from '../../../shared/templates/crud/crud.template';

@Injectable({
    providedIn: 'root'
})
export class MemberService extends CrudTemplateService<Member> {
    constructor(private __injector: Injector) {
        super(__injector, Member, '/api/members', {
            create: EMaPUIPermission.User_Create,
            read: true,
            update: true,
            delete: EMaPUIPermission.User_Delete
        });
    }

    public isTwoFAuthUsed() {
        return this._http.post<{ used: boolean }>(`/twoFa`, { action: 'isTwofaUsed' });
    }

    public generateTwoFAuthKey() {
        return this._http.post(`/twoFa`, { action: 'generateKey' });
    }

    public checkTempTwoFAuthToken(token: string) {
        return this._http.post(`/twoFa`, { action: 'checkTempKey', token: token }, { responseType: 'text' });
    }

    public removeTwoFAuth() {
        return this._http.post(`/twoFa`, { action: 'disable' }, { responseType: 'text' });
    }

    public getCurrentHospitalMembers(): Observable<Array<IMember>> {
        const endpoint = `${this._endPoint}/mine`;
        return this._http.get<Array<IMember>>(endpoint);
    }

    public getPaginatedHospitalMembers = (
        pageOptions: IPaginationOptions,
        search?: string,
        sort?: Sort,
        filters?: Array<IFilter>,
        otherParams?: Array<IHttpRequestParam>
    ): Observable<IPaginationResult<IMember>> => {
        let params = new HttpParams();
        // Begin assigning parameters
        if (search) {
            params = params.append('search', search);
        }
        if (filters) {
            params = params.append('filters', JSON.stringify(filters));
        }
        if (sort) {
            params = params.append('sorting', JSON.stringify(sort));
        }
        if (!pageOptions) {
            //if (pageOptions)
            pageOptions = { page: 1, limit: 10 };
        } //todo: Remove when admin v1 is not used anymore
        params = params.append('pageOptions', JSON.stringify(pageOptions));

        params = this.addOtherParams(params, otherParams);
        return this._http.get<IPaginationResult<IMember>>(`/api/hospitals/paginated-members`, { params: params });
    };
}
