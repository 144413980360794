const buildProviderScope = (scope: string) => ({
    scope,
    alias: scope.replace(/\/(.)/g, (_, char) => char.toUpperCase())
});

export const SCOPE = {
    AUTH: 'auth',
    HOSPISTOCK: 'hospistock',
    HOSPIBOARD: 'hospiboard',
    NAVIGATION: 'navigation',
    PHARMACY: 'pharmacy',
    // ADMIN
    ADMIN_ARS: buildProviderScope('admin/ars'),
    ADMIN_CRON: buildProviderScope('admin/cron'),
    ADMIN_GROUPS: buildProviderScope('admin/groups'),
    ADMIN_HOSPISTOCK: buildProviderScope('admin/hospistock'),
    ADMIN_HOSPITALS: buildProviderScope('admin/hospitals'),
    ADMIN_LABOS: buildProviderScope('admin/labos'),
    ADMIN_MEMBERS: buildProviderScope('admin/members'),
    ADMIN_ONLINE_USERS: buildProviderScope('admin/online-users'),
    ADMIN_PHARMACIES: buildProviderScope('admin/pharmacies'),
    ADMIN_PURCHASE_GROUPS: buildProviderScope('admin/purchase-groups'),
    ADMIN_RELEASE_NOTES: buildProviderScope('admin/release-notes'),
    ADMIN_ROLES: buildProviderScope('admin/roles'),
    // EXCHANGE
    EXCHANGE: 'exchange',
    EXCHANGE_ARCHIVES: buildProviderScope('exchange/archives'),
    EXCHANGE_BILLING: buildProviderScope('exchange/billing'),
    EXCHANGE_DASHBOARD: buildProviderScope('exchange/dashboard'),
    EXCHANGE_LOAN: buildProviderScope('exchange/loan'),
    EXCHANGE_STATISTICS: buildProviderScope('exchange/statistics'),
    // SETTING
    SETTING_GROUPS: buildProviderScope('setting/groups'),
    SETTING_HOSPITAL_ACCOUNT: buildProviderScope('setting/hospital-account'),
    SETTING_USER_ACCOUNT: buildProviderScope('setting/user-account'),
    // SHARED
    SHARED: 'shared',
    SHARED_FILE: buildProviderScope('shared/file'),
    SHARED_HOSPITAL: buildProviderScope('shared/hospital'),
    SHARED_SEARCH: buildProviderScope('shared/search'),
    SHARED_SPREADSHEET: buildProviderScope('shared/spreadsheet'),
    SHARED_WIDGET: buildProviderScope('shared/widget')
};
