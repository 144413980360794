import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MessageIconBoxModule } from '../message-icon-box/message-icon-box.module';
import { DynamicDataContainerContentDirective } from './dynamic-data-container-content.directive';

import { DynamicDataContainerComponent } from './dynamic-data-container.component';

@NgModule({
    imports: [CommonModule, MessageIconBoxModule],
    declarations: [DynamicDataContainerComponent, DynamicDataContainerContentDirective],
    exports: [DynamicDataContainerComponent, DynamicDataContainerContentDirective]
})
export class DynamicDataContainerModule {}
