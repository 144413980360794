import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { TranslocoModule, provideTranslocoScope } from '@jsverse/transloco';

import { FuseNavigationComponent } from './navigation.component';
import { FuseNavVerticalCollapsableComponent } from './collapsable/collapsable.component';
import { FuseNavVerticalGroupComponent } from './collapsable/group/group.component';
import { FuseNavVerticalItemComponent } from './collapsable/item/item.component';
import { SCOPE } from '@app/shared/constTypes/transloco-scopes.const';

@NgModule({
    imports: [CommonModule, RouterModule, MatIconModule, MatRippleModule, TranslocoModule],
    exports: [FuseNavigationComponent],
    declarations: [
        FuseNavigationComponent,
        FuseNavVerticalGroupComponent,
        FuseNavVerticalItemComponent,
        FuseNavVerticalCollapsableComponent
    ],
    providers: [provideTranslocoScope(SCOPE.NAVIGATION)]
})
export class FuseNavigationModule {}
