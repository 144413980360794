import { Injectable } from '@angular/core';

import { IHospital, IMember, Member } from '@mapuilabs/mpl-interfaces';

import { Auth } from '@core/auth/auth.service';
import { MemberService } from '@core/services/member/member.service';

@Injectable({
    providedIn: 'root'
})
export class IdentityService {
    constructor(
        private _authService: Auth,
        private _memberService: MemberService
    ) {}

    get user(): IMember {
        return this._authService.user;
    }

    get hospital(): IHospital {
        return this._authService.user ? this._authService.user.hospital : null;
    }

    public toggleFoldedNavBar(isOpened: boolean): void {
        if (isOpened !== this.user.config.sidenavOpened) {
            this.user.config.sidenavOpened = isOpened;
            this._memberService.save(this.user as Member).subscribe(() => {
                return this._authService.updateUserCookie(this.user);
            });
        }
    }
}
