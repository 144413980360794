import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EEstablishmentType, Establishment } from '@mapuilabs/mpl-interfaces';
import { Observable } from 'rxjs';
import { PermissionService } from '../permission/permission.service';

@Injectable({
    providedIn: 'root'
})
export class EstablishmentService {
    private _endpoint = 'api/external/establishment';

    constructor(
        private _permissionService: PermissionService,
        private _http: HttpClient
    ) {}

    // Returns establishment matching given string and type
    public findByNameAndType(name: string, types: Array<EEstablishmentType> = []): Observable<Establishment[]> {
        const filterCodes = [];
        types.forEach((type) => {
            const code = Establishment.getCodesByEstablishmentType(type);
            filterCodes.push(...code);
        });

        const params: HttpParams = new HttpParams().set('name', name).set('types', JSON.stringify(filterCodes));
        return this._http.get<Establishment[]>(this._endpoint, { params: params });
    }
}
