import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { EHistoryAction, EHistoryType, History, IHistory } from '@mapuilabs/mpl-interfaces';

import { CrudTemplateService } from '@app/shared/templates/crud/crud.template';

@Injectable({
    providedIn: 'root'
})
export class HistoryService extends CrudTemplateService<History> {
    constructor(private __injector: Injector) {
        super(__injector, History, '/api/history', {
            create: true,
            read: null,
            update: null
        });
    }

    public pushNavigation(url: string): Observable<History> {
        return this.add({
            type: EHistoryType.Navigation,
            action: EHistoryAction.Open,
            target: url
        } as IHistory);
    }
}
