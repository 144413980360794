import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BytesPipe } from './bytes/bytes.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [BytesPipe],
    exports: [BytesPipe]
})
export class PipesModule {}
