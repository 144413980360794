import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { AASchema, EHTTPStatus, IModel } from '@mapuilabs/mpl-interfaces';
import { PermissionService } from '@core/services/permission/permission.service';
import { ClassType } from 'class-transformer/ClassTransformer';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { CrudReadonly } from './crud-readonly.class';
import { ICrudPermission } from './crud.types';

export class CrudTemplateService<T extends IModel | AASchema> extends CrudReadonly<T> {
    protected _http: HttpClient;
    protected _permissionService: PermissionService;

    constructor(
        protected _injector: Injector,
        protected _ctor: ClassType<T>,
        protected _endPoint: string,
        protected _permissions: ICrudPermission
    ) {
        super(_injector, _ctor, _endPoint, _permissions);
    }

    public save(obj: Partial<T>, options?: any): Observable<T> {
        if (!this._permissionService.authorize(this._permissions.update)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!obj || !obj._id) {
            return throwError(() => EHTTPStatus.BadRequest);
        }

        const fullEndPoint = `${this._endPoint}/${obj._id}`;
        return this._http.put(fullEndPoint, obj, options).pipe(map((data) => new this._ctor(data)));
    }

    public add(obj: T): Observable<T> {
        if (!this._permissionService.authorize(this._permissions.create)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!obj) {
            return throwError(() => EHTTPStatus.BadRequest);
        }

        return this._http.post(this._endPoint, obj).pipe(map((data) => new this._ctor(data)));
    }

    public delete(obj: T): Observable<string> {
        if (!this._permissionService.authorize(this._permissions.delete)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!obj || !obj._id) {
            return throwError(() => EHTTPStatus.BadRequest);
        }

        const fullEndPoint = `${this._endPoint}/${obj._id}`;
        return this._http.delete(fullEndPoint, { responseType: 'text' });
    }
}
