import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader, TranslocoService } from '@jsverse/transloco';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs';

export const loadTranslocoScope = (service: TranslocoService, scope: string) =>
    new Promise((resolve, reject) =>
        service.load(`${scope}/${service.getDefaultLang()}`).pipe(take(1)).subscribe({
            next: resolve,
            error: reject
        })
    );

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    private http = inject(HttpClient);

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    }
}
