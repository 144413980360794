import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';

import { PermissionService } from '@core/services/permission/permission.service';

export const AuthGuardChild: CanActivateChildFn = async (route) => {
    const router: Router = inject(Router);
    const permissionService = inject(PermissionService);

    // check user permission to route
    if (permissionService.checkPermission(route)) {
        return true;
    }

    // no access so redirect to the home-page
    return router.parseUrl(permissionService.getHomePage());
};
