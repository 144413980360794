import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { FuseSidebarModule } from '../fuse-sidebar/fuse-sidebar.module';
import { DirectivesModule } from '@app/shared/directives/directives';

@NgModule({
    imports: [CommonModule, FuseSidebarModule, DirectivesModule],
    declarations: [SidebarComponent],
    exports: [SidebarComponent]
})
export class SidebarModule {}
