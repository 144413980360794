import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
    EAdminPanelPermission,
    EHospistockPermission,
    EMaPUIPermission,
    IHospital,
    Permissions
} from '@mapuilabs/mpl-interfaces';
import { lastValueFrom } from 'rxjs';

type UserPermissions = {
    [key in Permissions]: boolean;
};

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    /**
     * List of current user accesses
     */
    private _userPermissions: UserPermissions;

    constructor(private _httpClient: HttpClient) {}

    /**
     * Update list of permissions
     */
    public async updateUserPermissions() {
        if (!this._userPermissions) {
            this._userPermissions = await lastValueFrom(this._httpClient.get<UserPermissions>('/api/permissions'));
        }
    }

    public setUserPermissions(userPermissions: UserPermissions) {
        this._userPermissions = userPermissions;
    }

    /**
     * Tells if the current user has access to the given permission
     * @param permissions
     */
    public authorize(permissions: true | Permissions | Permissions[]): boolean {
        if (permissions === true) {
            return true;
        } else if (permissions instanceof Array) {
            return permissions.some((permission) => this.authorize(permission));
        } else {
            return this._userPermissions ? this._userPermissions[permissions] : false;
        }
    }

    public getHomePage(): string {
        if (this.authorize(EMaPUIPermission.Home_Read)) {
            return '/home';
        } else if (this.authorize(EHospistockPermission.LaboDrugDashboard_SeeMenuItem)) {
            return '/hospistock/labo/drug/dashboard';
        } else if (this.authorize(EHospistockPermission.LaboMedicalDeviceDashboard_SeeMenuItem)) {
            return '/hospistock/labo/medical-device/dashboard';
        } else if (this.authorize(EHospistockPermission.PurchaseGroupDrugDashboard_SeeMenuItem)) {
            return '/hospistock/purchase-group/drug/dashboard';
        } else if (this.authorize(EHospistockPermission.PurchaseGroupMedicalDeviceDashboard_SeeMenuItem)) {
            return '/hospistock/purchase-group/medical-device/dashboard';
        } else if (this.authorize(EHospistockPermission.PharmacyDrugDashboard_SeeMenuItem)) {
            return '/hospistock/pharmacy/drug/dashboard';
        } else if (this.authorize(EHospistockPermission.PharmacyMedicalDeviceDashboard_SeeMenuItem)) {
            return '/hospistock/pharmacy/medical-device/dashboard';
        } else if (this.authorize(EMaPUIPermission.DashboardLabo_SeeMenuItem)) {
            return '/hospiboard/labo/dashboard';
        } else {
            throw new Error('No home page found for this user');
        }
    }

    public checkPermission(route: ActivatedRouteSnapshot): boolean {
        // Route is open bar, maybe route.children has access
        if (!route.data?.access) {
            return true;
        }

        // Check the authentication status
        if (this.authorize(route.data.access)) {
            return true;
        }

        // Prevent the access
        return false;
    }

    /**
     * Tells if the hospital provided has access of the given permissions
     * @param hospital
     * @param project
     * @param permissionsArray
     */
    public hospitalHasPermissions(
        hospital: IHospital,
        permissionsArray: (EMaPUIPermission | EHospistockPermission | EAdminPanelPermission | string)[]
    ): boolean {
        const roles = hospital.roles;
        const permissions = permissionsArray.map((permission) => (<string>permission).split('|')[1]);
        const rolesHospital = roles.flatMap((role) => {
            return role.permissions;
        });

        return permissions.every((permission) => rolesHospital.includes(permission));
    }

    public canCreateInst(): boolean {
        return this.canCreateLoanMedInst() || this.canCreateBorrowingMedInst();
    }

    public canCreateLoanMedInst(): boolean {
        return this.authorize(EMaPUIPermission.MedInst_CreateLoan);
    }

    public canCreateBorrowingMedInst(): boolean {
        return this.authorize(EMaPUIPermission.MedInst_CreateBorrowing);
    }

    public canDoPrep(): boolean {
        return this.canCreateLoanPrepInst() || this.canCreateBorrowingPrepInst();
    }

    public canCreateLoanPrepInst(): boolean {
        return this.authorize(EMaPUIPermission.PrepInst_CreateLoan);
    }

    public canCreateBorrowingPrepInst(): boolean {
        return this.authorize(EMaPUIPermission.PrepInst_CreateBorrowing);
    }
}
