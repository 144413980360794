import { AfterViewInit, Component, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { delay } from 'rxjs/operators';

import { Permissions } from '@mapuilabs/mpl-interfaces';

import { FuseNavigationService } from '@app/layout/navbar/navigation/navigation.service';
import { FuseSidebarService } from '@app/shared/components/fuse-sidebar/fuse-sidebar.service';
import { FusePerfectScrollbarDirective } from '@app/shared/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { IdentityService } from '@core/services/identity/identity.service';
import { NavBarService } from '@core/services/nav-bar-subject/nav-bar-subject.service';
import { PermissionService } from '@core/services/permission/permission.service';
import { navigation } from '../../../navigation';
import { FuseNavigationItem } from './navigation/navigation.types';

interface CustomFuseNavigationItemDisplayFn extends FuseNavigationItem {
    displayFn?: (injector: Injector, item: FuseNavigationItem) => boolean;
    permissions?: never;
}

interface CustomFuseNavigationItemPermissions extends FuseNavigationItem {
    displayFn?: never;
    permissions?: Permissions | Permissions[];
}

export type CustomFuseNavigationItem = CustomFuseNavigationItemDisplayFn | CustomFuseNavigationItemPermissions;

export interface CustomFuseNavigation extends FuseNavigationItem {
    children?: CustomFuseNavigationItem[];
}

@Component({
    selector: 'mpx-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, AfterViewInit {
    private _navigation = navigation;
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;

    public folded: boolean;

    @ViewChild(FusePerfectScrollbarDirective, { static: true })
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }
        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(delay(500))
            .subscribe(() => this._fusePerfectScrollbar.update());
    }

    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _fuseNavigationService: FuseNavigationService,
        private _identityService: IdentityService,
        private _injector: Injector,
        private _navBarService: NavBarService,
        private _permissionService: PermissionService
    ) {}

    ngOnInit(): void {
        if (!this._fuseNavigationService.getNavigation('navbar')) {
            this._navigation.forEach((nav) => {
                this._handleNavbarItem(nav);
            });

            this._fuseNavigationService.register('navbar', this._navigation);
            this._fuseNavigationService.setCurrentNavigation('navbar');
        }

        // folded -> not open
        this.folded = !this._identityService.user.config.sidenavOpened;
        if (this.folded) {
            document.getElementsByTagName('body')[0].classList.add('navbar-folded');
        } else {
            document.getElementsByTagName('body')[0].classList.remove('navbar-folded');
        }

        setTimeout(() => {
            this._fusePerfectScrollbar.scrollToElement('.nav-link.active', -120);
        }, 50);
    }

    ngAfterViewInit(): void {
        this._fuseSidebarService.getSidebar('navbar').openedChanged.subscribe((isOpen) => {
            if (isOpen === false) {
                document.getElementsByTagName('body')[0].classList.remove('navbar-folded');
            }
        });
    }

    private _handleNavbarItem(navigation: CustomFuseNavigationItem): void {
        // Go through navigation recurcively
        if (navigation.children?.length) {
            navigation.children.forEach((nav) => this._handleNavbarItem(nav));
        }

        this._hideNavbarItemByPermission(navigation);
        this._handleNavbarItemFunction(navigation);
    }

    private _hideNavbarItemByPermission(navigation: CustomFuseNavigationItem): void {
        if (Array.isArray(navigation.permissions)) {
            navigation.hidden = this._permissionService.authorize(navigation.permissions) === false;
        } else if (typeof navigation.permissions === 'string') {
            navigation.hidden = this._permissionService.authorize(navigation.permissions) === false;
        }
    }

    private _handleNavbarItemFunction(navigation: CustomFuseNavigationItem): void {
        if (navigation.displayFn) {
            navigation.hidden = !navigation.displayFn(this._injector, navigation);
        }
    }

    public toggleFolded(): void {
        this.folded = !this.folded;
    }

    public foldedChanged(folded: boolean): void {
        if (folded) {
            document.getElementsByTagName('body')[0].classList.add('navbar-folded');
        } else {
            document.getElementsByTagName('body')[0].classList.remove('navbar-folded');
        }
        this._navBarService.navBarUnfolded.next(!folded);
        this._identityService.toggleFoldedNavBar(!folded);
    }
}
