import { NgModule } from '@angular/core';
import { MpxButtonDirective } from './button/button.directive';
import { MpxButtonsContainerDirective } from './buttons-container/buttons-container.directive';
import { MpxClickOutsideDirective } from './click-outside/click-outside.directive';
import { FusePerfectScrollbarDirective } from './fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { MpxUpperCaseInputDirective } from './upper-case-input/to-upper-case.directive';

@NgModule({
    declarations: [
        FusePerfectScrollbarDirective,
        MpxButtonDirective,
        MpxButtonsContainerDirective,
        MpxClickOutsideDirective,
        MpxUpperCaseInputDirective
    ],
    imports: [],
    exports: [
        FusePerfectScrollbarDirective,
        MpxButtonDirective,
        MpxButtonsContainerDirective,
        MpxClickOutsideDirective,
        MpxUpperCaseInputDirective
    ]
})
export class DirectivesModule {}
