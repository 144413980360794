import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';

import { EEnv, ESocketTopic, IMember } from '@mapuilabs/mpl-interfaces';

import { Utils } from '@core/services/Utils';
import { SnackbarService } from '../snackbar/snackbar.service';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    private _socket: Socket;
    private _onlineMembers: IMember[];

    constructor(private _snackbarService: SnackbarService) {}

    public connect(): void {
        const port = Utils.env === EEnv.Dev ? 4646 : 443;

        this._socket = io('//' + location.hostname + ':' + port + '/', {
            secure: true,
            transports: ['websocket', 'polling']
        });

        this._socket.on(ESocketTopic.OnlineMembers, (args: IMember[]) => {
            this._onlineMembers = args;
        });

        this._socket.on('disconnect', () => {
            this._snackbarService.openDefaultError();
        });

        this._socket.on('connect_error', () => {
            this._snackbarService.openDefaultError();
        });
    }

    public on(eventName: ESocketTopic, callback: (msg) => void): void {
        this._socket.on(eventName, callback);
    }
}
