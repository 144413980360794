import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { Auth } from '@app/core/auth/auth.service';

export const AuthGuard: CanActivateFn = async (route, state) => {
    const auth = inject(Auth);
    const router = inject(Router);
    // Check the authentication status
    const authenticated = await auth.check();

    // If the user is not authenticated...
    if (!authenticated) {
        // Redirect to the sign-in page with a redirect param
        return router.parseUrl(`/auth/login?redirect=${state.url}`);
    }

    // Allow the access
    return true;
};
