import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';

import { Auth } from '@app/core/auth/auth.service';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = async () => {
    const auth = inject(Auth);
    const router = inject(Router);
    // Check the authentication status
    const authenticated = await auth.check();

    // If the user is already authenticated...
    if (authenticated) {
        // Redirect to the root (and then to his home page)
        return router.parseUrl('');
    }
    // Allow the access
    return true;
};
