<fuse-sidebar
    id="fuse-side-navbar"
    class="theme-fuse-navy dark"
    name="navbar"
    lockedOpen="gt-sm"
    [folded]="folded"
    (foldedChanged)="foldedChanged($event)"
>
    <div class="navbar-top primary-900">
        <div class="logo">
            <img class="logo-icon" src="../../../../assets/images/mapui/pill.png" />

            <span class="logo-text">MaPUI</span>
        </div>

        <div class="buttons" (click)="toggleFolded()">
            <mat-icon class="fold-icon" svgIcon="menu-open"></mat-icon>
        </div>
    </div>

    <div
        class="navbar-scroll-container primary-700"
        fusePerfectScrollbar
        [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
    >
        <fuse-navigation class="material2"></fuse-navigation>
    </div>
</fuse-sidebar>
