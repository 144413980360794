import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { lastValueFrom } from 'rxjs';

import {
    ENotificationType,
    ESocketTopic,
    INotification,
    INotificationCount,
    Notification
} from '@mapuilabs/mpl-interfaces';

import { FuseNavigationService } from '@app/layout/navbar/navigation/navigation.service';
import { IdentityService } from '@core/services/identity/identity.service';

import { BADGES_CONFIG, IBadgeConfig, ICONS_CONFIG } from '@shared/constTypes/notification/notification.config';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';
import { SocketService } from '../socket/socket.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends CrudTemplateService<INotification> {
    private count: INotificationCount = { unread: {}, unseen: 0 };

    public notifications: INotification[] = [];
    public formattedCount: string;

    constructor(
        private __injector: Injector,
        private _fuseNavigationService: FuseNavigationService,
        private _identityService: IdentityService,
        private _router: Router,
        private _socketService: SocketService
    ) {
        super(__injector, Notification, '/api/members/', null);
        this._endPoint += this._identityService.user._id;
    }

    listenNotifications(): void {
        this._socketService.on(ESocketTopic.Notification, (notif: INotification) => {
            this._addNotifications([notif], true);
            this.count.unseen++;
            if (!this.count.unread[notif.type]) {
                this.count.unread[notif.type] = 0;
            }
            this.count.unread[notif.type]++;
            this.getCount();
            this._updateNavigationBadges();
        });

        this._socketService.on(ESocketTopic.NotificationUpdate, () => {
            this.getCount();
            this._updateNavigationBadges();
        });
    }

    async getCount(): Promise<void> {
        this.count = await lastValueFrom(this._http.get<INotificationCount>(`${this._endPoint}/notifications/count`));
        this.formattedCount = this.getFormattedCount(this.count);
        this._updateNavigationBadges();
    }

    /**
     * Format counter of notifications by displaying 99+ if the value is >= 100
     * @param {string} count
     * @return {string}
     */
    private getFormattedCount(count: INotificationCount): string {
        if (count) {
            if (!count.unseen) {
                return '';
            } else {
                return count.unseen < 100 ? count.unseen.toString() : '99+';
            }
        } else {
            return '';
        }
    }

    private _addNotifications = (notifications: Array<INotification>, atFirst = false): void => {
        const formattedNotifications = notifications.map((notification: INotification) => {
            notification.label =
                'NOTIFICATIONS.LABEL.' + notification.type.toUpperCase() + '.' + notification.action.toUpperCase();
            notification.date = new Date(notification.date);
            notification.relativeTime = moment.duration(moment().diff(notification.date)).humanize();
            notification.icon = ICONS_CONFIG[notification.type];
            return notification;
        });

        this.notifications = atFirst
            ? [...formattedNotifications, ...this.notifications]
            : [...this.notifications, ...formattedNotifications];
    };

    /**
     * Mark all notifications as read or seen
     */
    public markAllAs(param: 'read' | 'seen'): void {
        const body = {};
        const url = `${this._endPoint}/notifications`;
        this.notifications.forEach((notification: INotification) => {
            notification[param] = true;
        });
        if (param === 'read') {
            this.count.unread = Object.entries(this.count.unread).reduce(
                (acc, [key, value]) => ({ ...acc, [key]: value }),
                {}
            );
        } else {
            body['type'] = 'seen';
        }
        this.count.unseen = 0;
        this._http.put(url, body, { responseType: 'text' }).subscribe(
            () => {
                if (param === 'read') {
                    this._updateNavigationBadges();
                }
            },
            (err) => console.error(err)
        );
    }

    public open(notif: INotification): void {
        if (!notif.read) {
            this.count.unread[notif.type]--;
            notif.read = true;
            this.markOneAsRead(notif._id);
            this._updateNavigationBadges();
        }

        switch (notif.type) {
            case ENotificationType.Loan:
            case ENotificationType.Borrowing:
                this._router.navigateByUrl('/exchange/dashboard');
                break;
            case ENotificationType.Preparation:
                this._router.navigateByUrl('/pharmacy/preparations');
                break;
        }
    }

    public markOneAsRead(id: string): void {
        this._http.put(`${this._endPoint}/notifications/${id}`, null, { responseType: 'text' }).subscribe();
    }

    public async load(): Promise<void> {
        const res = await lastValueFrom(this._http.get<Array<INotification>>(`${this._endPoint}/notifications`));

        this._addNotifications(res);
    }

    private _countPerCategories(args: ENotificationType[]): number {
        for (const key of args) {
            if (this.count.unread[key] > 0) {
                return this.count.unread[key];
            }
        }
        return 0;
    }

    private _updateNavigationBadges() {
        BADGES_CONFIG.map((conf: IBadgeConfig) => ({
            ...conf,
            nbr: this._countPerCategories(conf.notificationTypes)
        })).forEach((badge) => this._updateBadge(badge));
    }

    private _updateBadge(badge: IBadgeConfig & { nbr: number }): void {
        if (badge.nbr > 0) {
            this._fuseNavigationService.updateNavigationItem(badge.id, {
                badge: {
                    title: badge.nbr,
                    bg: badge.color || '#F44336'
                }
            });
        } else {
            this._fuseNavigationService.updateNavigationItem(badge.id, {
                badge: null
            });
        }
    }
}
