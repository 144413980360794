import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import * as Sentry from '@sentry/angular-ivy';
import { BehaviorSubject, filter, startWith } from 'rxjs';

import { EHospistockPermission, ESocketTopic } from '@mapuilabs/mpl-interfaces';

import { FuseSidebarComponent } from '@app/shared/components/fuse-sidebar/fuse-sidebar.component';
import { FuseSidebarService } from '@app/shared/components/fuse-sidebar/fuse-sidebar.service';

import { Auth } from '@core/auth/auth.service';
import { HistoryService } from '@core/services/history/history.service';
import { PermissionService } from '@core/services/permission/permission.service';
import { SocketService } from '@core/services/socket/socket.service';
import { Utils } from '@core/services/Utils';

import { VaultModalComponent } from '../vault-modal/vault-modal.component';

@Component({
    selector: 'mpx-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MainComponent implements AfterViewInit {
    public isOpen$: BehaviorSubject<boolean>;
    public navbar: FuseSidebarComponent;
    public idleModal: MatDialogRef<VaultModalComponent>;

    constructor(
        private _auth: Auth,
        private _fuseSidebarService: FuseSidebarService,
        private _historyService: HistoryService,
        private _idle: Idle,
        private _matDialog: MatDialog,
        private _permissionService: PermissionService,
        private _router: Router,
        private _socketService: SocketService
    ) {
        this.isOpen$ = new BehaviorSubject(false);

        this._socketService.connect();

        this._socketService.on(ESocketTopic.Permissions, (msg) => {
            this._permissionService.setUserPermissions(msg);
        });
        this._socketService.on(ESocketTopic.Roles, (msg) => {
            this._auth.user.roles = msg.roles;
            this._auth.user.hospital.roles = msg.hospital.roles;
        });

        Sentry.getCurrentScope().setUser({
            id: Utils.getId(this._auth.user),
            username: this._auth.user.fullName,
            email: this._auth.user.email
        });

        this._router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this._historyService.pushNavigation(event.urlAfterRedirects).subscribe();
            });

        if (this._permissionService.authorize(EHospistockPermission.VaultDisconnection_Read)) {
            this.initVault();
        }
    }

    ngAfterViewInit(): void {
        this.navbar = this._fuseSidebarService.getSidebar('navbar');

        this.navbar.openedChanged.pipe(startWith(this.navbar.opened)).subscribe({
            next: (value) => this.isOpen$.next(value)
        });
    }

    private initVault() {
        this._idle.setIdle(60 /* seconds */ * 27 /* min */); // Duration seconds of inactivity to consider a user idle
        this._idle.setTimeout(60 /* seconds */ * 3 /* min */); // Duration in seconds to display a warning before actually terminate user session
        this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this._idle.onIdleStart.subscribe(() => {
            this.idleModal = this._matDialog.open(VaultModalComponent, { minWidth: '40%' });
        });
        this._idle.onIdleEnd.subscribe(() => {
            this.idleModal?.close();
        });
        this._idle.watch();
    }
}
