import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IdentityService } from '@core/services/identity/identity.service';

@Injectable({
    providedIn: 'root'
})
export class NavBarService {
    public navBarUnfolded: BehaviorSubject<boolean>;

    constructor(private _identityService: IdentityService) {
        this.navBarUnfolded = new BehaviorSubject<boolean>(this._identityService.user.config.sidenavOpened);
    }
}
