import { Component } from '@angular/core';
import { Idle } from '@ng-idle/core';

import { Auth } from '@core/auth/auth.service';

@Component({
    templateUrl: './vault-modal.component.html'
})
export class VaultModalComponent {
    public countdown: number;
    public spinnerProgress: number;

    constructor(
        private _auth: Auth,
        private _idle: Idle
    ) {
        this.countdown = 60 * 3;
        this.spinnerProgress = 100;
        this._idle.onTimeoutWarning.subscribe((seconds) => {
            this.countdown = seconds;
            this.spinnerProgress = (seconds / (60 * 3)) * 100;
        });
        this._idle.onTimeout.subscribe(() => {
            this._auth.logout();
        });
    }
}
