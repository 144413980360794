import { Injectable, Injector } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';

import {
    EEstablishmentType,
    EMaPUIPermission,
    Establishment,
    Hospital,
    IHospital,
    ILoan,
    IMember,
    IPreparation
} from '@mapuilabs/mpl-interfaces';

import { HttpParams } from '@angular/common/http';
import { EstablishmentService } from '@app/core/services/establishment/establishment.service';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';

@Injectable({
    providedIn: 'root'
})
export class HospitalService extends CrudTemplateService<Hospital> {
    constructor(
        private __injector: Injector,
        private _establishmentService: EstablishmentService
    ) {
        super(__injector, Hospital, '/api/hospitals', {
            // Read is allowed for all logged in user
            read: true,
            create: EMaPUIPermission.Establishment_Edit,
            update: EMaPUIPermission.Establishment_Edit
        });
    }

    /**
     * Get all members of a given hospital
     * @param hid
     */
    public getMembers(hid: string): Observable<Array<IMember>> {
        const endpoint = `${this._endPoint}/${hid}/members`;
        return this._http.get<Array<IMember>>(endpoint);
    }

    /**
     * Return an IHospital with only the groups attribute (populated)
     */
    public getGroups(): Observable<IHospital> {
        const endpoint = `${this._endPoint}/groups`;
        return this._http.get<IHospital>(endpoint);
    }

    public getRequestLoans(hid: string): Observable<ILoan[]> {
        const endpoint = `${this._endPoint}/${hid}/loans/request`;
        return this._http.get<ILoan[]>(endpoint);
    }

    public getPreparations(hid: string): Observable<IPreparation[]> {
        const endpoint = `${this._endPoint}/${hid}/preparations`;
        const result = this._http.get<IPreparation[]>(endpoint);
        return result;
    }

    /**
     * Search hospital
     * @return {angular.IPromise<Array<Establishment>>}
     */
    public searchHospitals(search: string, type: EEstablishmentType): Observable<Array<Hospital | Establishment>> {
        if (EEstablishmentType.Other !== type) {
            return this._establishmentService.findByNameAndType(search, [type]);
        }
        return this.findByName(search, EEstablishmentType.Other);
    }

    public findByName(name: string, type: EEstablishmentType): Observable<Hospital[]> {
        const params = new HttpParams().set('name', name).set('type', type);

        return this._http.get<Hospital[]>(this._endPoint, { params });
    }

    /**
     * Create hospital from establishment
     * @param {Establishment} establishment
     */
    public createFromEstablishment(establishment: Establishment): Promise<Hospital> {
        let type = Establishment.getEstablishmentTypeByCode(establishment.category);

        if (type === EEstablishmentType.Other) {
            type = Establishment.getEstablishmentTypeByCode(establishment.aggregateCategory);
        }
        const h = new Hospital({
            name: establishment.name,
            addrNumber: establishment.addrNumber,
            addrStreet: establishment.addrStreet,
            addrCity: establishment.addrCity,
            addrZIP: establishment.addrZipCode,
            phoneSTD: establishment.phone,
            Fax: establishment.fax,
            finessCode: establishment.finess,
            finessCodeJur: establishment.finessJur,
            lng: establishment.location?.coordinates?.[0],
            lat: establishment.location?.coordinates?.[1],
            establishmentType: type,

            active: false
        });

        return lastValueFrom(this.add(h));
    }
}
