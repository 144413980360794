import { HttpClient, HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { PermissionService } from '@core/services/permission/permission.service';
import {
    EHTTPStatus,
    FilterValueType,
    IFilter,
    IHttpRequestParam,
    IPaginationOptions,
    IPaginationResult
} from '@mapuilabs/mpl-interfaces';
import { ClassType } from 'class-transformer/ClassTransformer';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICrudPermission } from './crud.types';

export class CrudReadonly<T> {
    protected _http: HttpClient;
    protected _permissionService: PermissionService;

    constructor(
        protected _injector: Injector,
        protected _ctor: ClassType<T>,
        protected _endPoint: string,
        protected _permissions: ICrudPermission
    ) {
        this._http = _injector.get(HttpClient);
        this._permissionService = _injector.get(PermissionService);
    }

    public getById(id: string): Observable<T> {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!id) {
            return throwError(() => EHTTPStatus.BadRequest);
        }

        const fullEndPoint = `${this._endPoint}/${id}`;
        return this._http.get(fullEndPoint).pipe(map((data) => new this._ctor(data)));
    }

    public getAll(): Observable<T[]> {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        return this._http.get<T[]>(this._endPoint);
    }

    /**
     *
     * @param search
     * @param sort
     * @param filters
     * @param otherParams
     */
    public getList = (
        search?: string,
        sort?: Sort,
        filters?: IFilter[],
        otherParams?: Array<IHttpRequestParam>
    ): Observable<T[]> => {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        let params = new HttpParams();
        // Begin assigning parameters
        if (search) {
            params = params.append('search', search);
        }
        if (filters) {
            params = params.append('filters', JSON.stringify(filters));
        }
        if (sort) {
            params = params.append('sorting', JSON.stringify(sort));
        }

        params = this.addOtherParams(params, otherParams);
        return this._http.get<T[]>(this._endPoint, { params: params });
    };

    /**
     *
     * @param pageOptions
     * @param search
     * @param sort
     * @param filters
     * @param otherParams
     */
    public getPage = (
        pageOptions: IPaginationOptions,
        search?: string,
        sort?: Sort,
        filters?: Array<FilterValueType>,
        otherParams?: Array<IHttpRequestParam>
    ): Observable<IPaginationResult<T>> => {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        let params = new HttpParams();
        // Begin assigning parameters
        if (search) {
            params = params.append('search', search);
        }
        if (filters) {
            params = params.append('filters', JSON.stringify(filters));
        }
        if (sort) {
            params = params.append('sorting', JSON.stringify(sort));
        }
        if (!pageOptions) {
            //if (pageOptions)
            pageOptions = { page: 1, limit: 10 };
        } //todo: Remove when admin v1 is not used anymore
        params = params.append('pageOptions', JSON.stringify(pageOptions));

        params = this.addOtherParams(params, otherParams);
        return this._http.get<IPaginationResult<T>>(this._endPoint, { params: params });
    };

    protected addOtherParams = (params: HttpParams, otherParams?: Array<IHttpRequestParam>): HttpParams => {
        if (otherParams) {
            for (const param of otherParams) {
                if (!param.param) {
                    console.error(`Parameter not found.`);
                }
                params = params.append(param.param, param.value);
            }
        }
        return params;
    };
}
