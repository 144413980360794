import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@jsverse/transloco';
import { Injectable } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

// https://material.angular.io/components/paginator/api#MatPaginatorIntl
@Injectable()
export class MpMatPaginatorIntl extends MatPaginatorIntl {
    private _unsubscribeAll: Subject<void> = new Subject<void>();

    constructor(private translate: TranslocoService) {
        super();

        // Subscribe to language changes
        this.translate.langChanges$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: () => {
                this.getAndInitTranslations();
            }
        });

        this.getAndInitTranslations();
    }

    getAndInitTranslations() {
        this.translate
            .selectTranslate(['PAGINATOR.ITEMS_PER_PAGE', 'PAGINATOR.NEXT_PAGE', 'PAGINATOR.PREVIOUS_PAGE'])
            .subscribe((translations) => {
                this.itemsPerPageLabel = translations[0];
                this.nextPageLabel = translations[1];
                this.previousPageLabel = translations[2];
                this.changes.next();
            });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 / ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} / ${length}`;
    };
}
